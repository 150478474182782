import React from 'react';
import Table from 'react-bootstrap/Table';
import { highlightDiffFormatter, niceDateFormatter, niceDateTimeFormatter, userNameFormatter } from '../../lib/helper/formatting';
import { invoiceDelete, invoiceSingleFetch, invoiceSingleUnload } from '../../lib/redux/actions/invoices';
import { connect } from 'react-redux';
import clsx from 'clsx';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import MarkActionedButton from '../../components/invoiceInsuranceDetails/MarkActionedButton';

const mapStateToProps = (state) => ({
  ...state.invoices,
  ...state.invoice,
});

const mapDispatchToProps = {
  invoiceSingleFetch,
  invoiceSingleUnload,
  invoiceDelete,
};

class InvoiceUpdatedInsuranceDetailsListTable extends React.Component {
  render() {
    const {
      invoices,
      reload,
    } = this.props;

    if (!invoices || invoices.length === 0) {
      return null;
    }
    
    return (
        <div style={{ overflow: 'none' }}>
          <Table striped hover responsive className="mb-0">
            <thead>
            <tr>
              {/*<th>Item ID</th>*/}
              <th>Inv. No</th>
              <th>Updated At</th>
              <th>By Contact</th>
              <th>Patient Name / DOB</th>
              <th>Insurer</th>
              <th>Membership Number / Auth Code (if supplied)</th>
              <th>Actioned By</th>
              <th className="text-right">Action</th>
            </tr>
            </thead>
            <tbody>
            {invoices.map((tableInvoice) => {
              const isProblem = tableInvoice.isEscalated || tableInvoice.isMissingContactDetails;

              const rowClasses = clsx({
                'table-row--paid': tableInvoice.isPaid && !tableInvoice.isVoid,
                'table-row--problem': isProblem,
                'table-row--writeoff': tableInvoice.isWrittenOff,
                'table-row--void': tableInvoice.isVoid,
              });

              return tableInvoice.insuranceUpdate.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((insuranceUpdate) => (
                  <tr key={tableInvoice.id + '-' + insuranceUpdate.id} className={rowClasses}>

                    <td>
                      <Link to={ROUTES.INVOICES.SINGLE.replace(':id', tableInvoice.id)} className="btn btn-sm btn-outline-dark">
                        {tableInvoice.invoiceNo}
                      </Link>

                      {tableInvoice.isEscalated && <i className="ml-2 fas fa-exclamation-circle text-danger" title="Escalated"/>}
                      {tableInvoice.disableChasing && <i className="ml-2 fas fa-pause-circle text-danger" title="Notifications Paused"/>}

                      {tableInvoice.isMissingContactDetails && tableInvoice?.missingContactDetailsType && (
                          <>
                            {tableInvoice.missingContactDetailsType.code === '0' && (
                                <i className="ml-2 fas fa-phone text-danger" title={tableInvoice.missingContactDetailsType.name}/>
                            )}
                            {tableInvoice.missingContactDetailsType.code === '10' && (
                                <i className="ml-2 fas fa-envelope text-danger" title={tableInvoice.missingContactDetailsType.name}/>
                            )}
                            {tableInvoice.missingContactDetailsType.code === '20' && (
                                <>
                                  <i className="ml-2 fas fa-phone text-danger" title={tableInvoice.missingContactDetailsType.name}/>
                                  <i className="ml-2 fas fa-envelope text-danger" title={tableInvoice.missingContactDetailsType.name}/>
                                </>
                            )}
                          </>
                      )}
                    </td>
                    <td>{insuranceUpdate.createdAt ? niceDateTimeFormatter(insuranceUpdate.createdAt) : null}</td>
                    <td>{highlightDiffFormatter(insuranceUpdate.requestedBy, insuranceUpdate.contactRecord.name)}{insuranceUpdate.contactEmail ? <> / {highlightDiffFormatter(insuranceUpdate.contactEmail,insuranceUpdate.contactRecord.email)}</> : null}{insuranceUpdate.contactPhone ? <> / {highlightDiffFormatter(insuranceUpdate.contactPhone,insuranceUpdate.contactRecord.mobileNumber)}</> : null}</td>
                    <td>{highlightDiffFormatter(insuranceUpdate.patientName, tableInvoice.patientFirstName + ' ' + tableInvoice.patientLastName)} / {highlightDiffFormatter(niceDateFormatter(insuranceUpdate.dateOfBirth),
                        niceDateFormatter(tableInvoice.dateOfBirth))}</td>
                    <td>
                      <strong>{insuranceUpdate.insurer.name}</strong>
                    </td>
                    <td>{insuranceUpdate.membershipNumber}{insuranceUpdate.authCode ? ' / ' + insuranceUpdate.authCode : null}</td>
                    <td>{userNameFormatter(insuranceUpdate.actionedBy)} - {insuranceUpdate.actionedDate ? niceDateTimeFormatter(insuranceUpdate.actionedDate) : null}</td>
                    <td className="text-sm-left text-md-right">
                      {!insuranceUpdate.actionedBy ? <MarkActionedButton invoiceInsuranceDetails={insuranceUpdate} reload={reload}/> : null}
                    </td>
                  </tr>
              ));
            })}
            </tbody>
          </Table>
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceUpdatedInsuranceDetailsListTable);
