import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as ROUTES from '../../lib/routing/frontend';
import MASpinner from '../common/MASpinner';
import { authByPath } from '../../lib/routing/authorisation';
import { getSubusersConsultantToManage, isSubUser } from '../../lib/helper/authorisation';
import Helmet from 'react-helmet';

const mapStateToProps = (state) => ({
  ...state.auth,
});

class RouteWithLayout extends React.Component {
  render() {
    const {
      layout: Layout,
      linkLogo = true,
      component: Component,
      secure = true,
      isAuthenticated,
      is2faAuthenticated,
      userData,
      redirectBack = false,
      ...rest
    } = this.props;

    if (secure && isAuthenticated === null) {
      return <MASpinner />;
    }
    if (secure && (!isAuthenticated || !is2faAuthenticated)) {
      //      console.log(
      //        'RouteWithLayout: Secure Route but user is not auth or 2fa auth - (secure && (!isAuthenticated || !is2faAuthenticated)) = true - Redirecting to login.',
      //        secure,
      //        isAuthenticated,
      //        is2faAuthenticated,
      //      );
      let redirect = ROUTES.LOGIN;
      if (redirectBack || this.props.location.pathname.toString().match(/^\/invoice-batch\/[\d+]/)) {
        redirect += '?redirectTo=' + this.props.location.pathname + this.props.location.search.replace('?', '&');
      }
      return <Redirect to={redirect} />;
      //    } else {
      //      console.log('RouteWithLayout: (secure && (!isAuthenticated || !is2faAuthenticated)) = false - NO REDIRECT.', secure, isAuthenticated, is2faAuthenticated);
    }

    if (secure && userData && !userData.dateTermsRead && rest.path !== ROUTES.TERMS) {
      //      console.log(
      //        'RouteWithLayout: Secure Route, user authed but User has not read and agreed to Terms. secure && userData && !userData.dateTermsRead && rest.path !== ROUTES.TERMS - REDIRECT TO TERMS.',
      //        secure,
      //        userData,
      //        userData.dateTermsRead,
      //        rest.path,
      //      );
      return <Redirect to={ROUTES.TERMS} />;
    }

    if (
      secure &&
      userData &&
      isSubUser(true) &&
      false === getSubusersConsultantToManage() &&
      rest.path !== ROUTES.SELECT_CONSULTANT &&
      rest.path !== ROUTES.SUBACCOUNT.ACCEPT_SECURE &&
      rest.path !== ROUTES.TERMS
    ) {
      //      console.log('RouteWithLayout: Secure Route, user authed but User is subuser and must select a consultant to manage');
      return <Redirect to={ROUTES.SELECT_CONSULTANT} />;
    }

    if (secure && userData && !authByPath(rest.path)) {
      //      console.log();
      //      console.log(
      //        'RouteWithLayout: Secure Route, user authed but authByPath fails for ' + rest.path + '. (secure && !authorised = true - Redirecting to home.',
      //        secure,
      //        authByPath(rest.path),
      //      );
      return <Redirect to={ROUTES.DENIED} />;
      //    } else {
      //      console.log(
      //        'RouteWithLayout: (secure && (!isAuthenticated || !is2faAuthenticated)) = false - NO REDIRECT.',
      //        secure,
      //        isAuthenticated,
      //        is2faAuthenticated,
      //        userData,
      //      );
    }

    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Layout linkLogo={linkLogo}>
            <Helmet titleTemplate="%s - MediACCOUNTS Portal" defaultTitle="MediACCOUNTS Portal">
              {rest.pageTitle && <title>{typeof rest.pageTitle === 'function' ? rest.pageTitle(rest) : rest.pageTitle}</title>}
            </Helmet>
            <Component {...matchProps} {...rest} />
          </Layout>
        )}
      />
    );
  }
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default connect(mapStateToProps, null)(RouteWithLayout);
