import React from 'react';
import Table from 'react-bootstrap/Table';
import { dateDiffFormatter, getSortIcon, invoiceAmountFormatter, niceDateFormatter } from '../../lib/helper/formatting';
import Modal from 'react-modal';
import { invoiceDelete, invoiceSingleFetch, invoiceSingleUnload } from '../../lib/redux/actions/invoices';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { renderCheckbox } from '../../lib/helper/form';
import clsx from 'clsx';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';

import OldInvoiceSingleRefOnly from '../../components/invoice/OldInvoiceSingleRefOnly';
import { isSeniorStaff } from '../../lib/helper/authorisation';

Modal.setAppElement('#root');

const mapStateToProps = (state) => ({
  ...state.invoices,
  ...state.invoice,
});

const mapDispatchToProps = {
  invoiceSingleFetch,
  invoiceSingleUnload,
  invoiceDelete,
};

const getInvoiceStatus = function(invoice) {
  if (invoice?.isVoid) {
    return 'Void';
  } else if (invoice?.isPaid) {
    return 'Paid';
  } else if (invoice?.isWrittenOff) {
    return 'WriteOff';
  } else {
    return 'Unpaid';
  }
}

class InvoiceListTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modalIsOpen: false,
      invoiceIDInModal: false,
    };
  }

  openModal = (invoiceID) => {
    this.setState({ modalIsOpen: true, invoiceIDInModal: invoiceID });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, invoiceIDInModal: false });
    this.props.invoiceSingleUnload();
    // Do we need to do some sort of unloading?
  };

  afterModalOpen = () => {
    this.props.invoiceSingleFetch(this.state.invoiceIDInModal);
  };

  render() {
    const {
      pageType,
      invoices,
      invoice,
      isFetchingInvoice,
      hasLoadedInvoice,
      showBulkSelect = false,
      bulkSelectAction = () => {},
      bulkSelectedInvoices = [],
    } = this.props;

    if (!invoices || invoices.length === 0) {
      return null;
    }
    return (
      <div style={{ overflow: 'none' }}>
        <Table striped hover responsive className="mb-0">
          <thead>
          <tr>
            {showBulkSelect && <th>Select</th>}
            {/*<th>Item ID</th>*/}
            <th>Inv. No</th>
            <th>Consultant</th>
            <th>Patient Name</th>
            <th>Service Date</th>
            <th
                className={'pointer'}
                onClick={() => {
                  return this.props.orderByInvoiceDate();
                }}>
              Inv. Date / (Days O/S) {getSortIcon(this.props.invoiceDateOrderBy)}
            </th>
            <th>O/S / Amount</th>
            <th>Assigned To</th>
            <th>Last Contact</th>
            {pageType === 'escalation' ? <th>Escalation Reason</th> : null}
            {pageType === 'escalation' ? <th>Escalation Status</th> : <th>Status</th>}
            {pageType === 'escalation' ? (
                <th
                    className={'pointer'}
                    onClick={() => {
                      return this.props.orderByRevisitDay();
                    }}>
                  Revisit Date {getSortIcon(this.props.revisitOrderBy)}
                </th>
            ) : null}
            {pageType === 'missing' ? <th className={'text-center'}>Consultant Contacted</th> : null}
            {pageType === 'missing' ? <th className={'text-center'}>Missing Data</th> : null}
            {isSeniorStaff() ? <th className={'text-center'}>Auto Chase</th> : null}
            <th className="text-right">Action</th>
          </tr>
          </thead>
          <tbody>
          {invoices.map((tableInvoice) => {
            const isBulkSelected = showBulkSelect && bulkSelectedInvoices.some((invoiceId) => invoiceId === tableInvoice.id);

            const isProblem = tableInvoice.isEscalated || tableInvoice.isMissingContactDetails;

              const rowClasses = clsx({
                'table-row--paid': tableInvoice.isPaid && !tableInvoice.isVoid,
                'table-row--problem': isProblem,
                'table-row--selected': isBulkSelected,
                'table-row--writeoff': tableInvoice.isWrittenOff,
                'table-row--void': tableInvoice.isVoid,
              });

              return (
                <tr key={tableInvoice.id} className={rowClasses}>
                  {showBulkSelect && (
                    <td>
                      <Form.Check
                        type={'switch'}
                        name={`invoices.${tableInvoice.id}`}
                        id={`invoices.${tableInvoice.id}`}
                        onChange={() => bulkSelectAction(tableInvoice)}
                        component={renderCheckbox}
                        className="mb-4"
                        checked={isBulkSelected}
                      />
                    </td>
                  )}

                  <td>
                    <Link to={ROUTES.INVOICES.SINGLE.replace(':id', tableInvoice.id)} className="btn btn-sm btn-outline-dark">
                      {tableInvoice.invoiceNo}
                    </Link>

                    {tableInvoice.isEscalated && <i className="ml-2 fas fa-exclamation-circle text-danger" title="Escalated" />}
                    {tableInvoice.disableChasing && <i className="ml-2 fas fa-pause-circle text-danger" title="Notifications Paused" />}

                    {tableInvoice.isMissingContactDetails && tableInvoice?.missingContactDetailsType && (
                      <>
                        {tableInvoice.missingContactDetailsType.code === '0' && (
                          <i className="ml-2 fas fa-phone text-danger" title={tableInvoice.missingContactDetailsType.name} />
                        )}
                        {tableInvoice.missingContactDetailsType.code === '10' && (
                          <i className="ml-2 fas fa-envelope text-danger" title={tableInvoice.missingContactDetailsType.name} />
                        )}
                        {tableInvoice.missingContactDetailsType.code === '20' && (
                          <>
                            <i className="ml-2 fas fa-phone text-danger" title={tableInvoice.missingContactDetailsType.name} />
                            <i className="ml-2 fas fa-envelope text-danger" title={tableInvoice.missingContactDetailsType.name} />
                          </>
                        )}
                      </>
                    )}
                  </td>
                  <td>{tableInvoice.consultant ? <strong>{tableInvoice.consultant.name}</strong> : ''}</td>
                  <td>
                    <strong>{tableInvoice.patientLastName + ', ' + tableInvoice.patientFirstName}</strong>
                  </td>
                  <td>{tableInvoice.serviceDate ? niceDateFormatter(tableInvoice.serviceDate, 'DD/MM/YYYY') : ''}</td>
                  <td>
                    {tableInvoice.invoiceDate ? niceDateFormatter(tableInvoice.invoiceDate, 'DD/MM/YYYY') : ''}
                    {tableInvoice.invoiceDate ? ' / (' + dateDiffFormatter(false, tableInvoice.invoiceDate) + ')' : ''}
                  </td>
                  <td>
                    <strong>{invoiceAmountFormatter(tableInvoice.outstandingBalance)}</strong> / {invoiceAmountFormatter(tableInvoice.invoiceAmount)}
                  </td>
                  <td>{tableInvoice.assignedTo ? <strong>{tableInvoice.assignedTo.firstName + ' ' + tableInvoice.assignedTo.lastName}</strong> : ''}</td>
                  <td>{tableInvoice.dateOfLastContact ? niceDateFormatter(tableInvoice.dateOfLastContact, 'DD/MM/YYYY') : ''}</td>
                  {pageType === 'escalation' ? <td>{tableInvoice.invoiceEscalationReason?.reason}</td> : null}
                  <td>
                    {pageType === 'escalation'
                      ? tableInvoice.invoiceEscalationStatus?.status
                      : getInvoiceStatus(tableInvoice)}
                  </td>
                  {pageType === 'escalation' ? <td>{tableInvoice.revisitDate ? niceDateFormatter(tableInvoice.revisitDate) : null}</td> : null}

                  {pageType === 'missing' ? <td className={'text-center'}>{tableInvoice?.consultantContactedForMissingDetails ? 'Yes' : 'No'}</td> : null}
                  {pageType === 'missing' ? (
                    <td className={'text-center'}>{tableInvoice?.missingContactDetailsType ? tableInvoice?.missingContactDetailsType.name : null}</td>
                  ) : null}

                  {isSeniorStaff() ? (
                    <td className={'text-center'}>
                      {tableInvoice?.consultant?.useAutomatedChasing ? (
                        <i className="fa fa-check-circle text-primary" title="Consultant uses automatic chasing" />
                      ) : (
                        <i className="fa fa-times-circle text-danger" title="Consultant NOT using automatic chasing" />
                      )}
                    </td>
                  ) : null}
                  <td className="text-sm-left text-md-right">
                    {tableInvoice?.consultant?.useAutomatedChasing ? (
                      <Link to={ROUTES.INVOICES.SINGLE.replace(':id', tableInvoice.id)} className="btn btn-sm btn-primary ml-2">
                        Process
                      </Link>
                    ) : (
                      <button className="btn btn-sm btn-primary ml-2" onClick={() => this.openModal(tableInvoice.id)}>
                        Process
                      </button>
                    )}
                    {/*<Link to={ROUTES.INVOICES.SINGLE.replace(':id', tableInvoice.id)} className="btn btn-sm btn-outline-dark">*/}
                    {/*  Process*/}
                    {/*</Link>*/}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterModalOpen}
          style={{
            content: {
              minWidth: '80%',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              border: 'none',
              background: 'none',
              zIndex: '1000',
            },
          }}
          shouldCloseOnOverlayClick={true}>
          <OldInvoiceSingleRefOnly fetchingInvoice={isFetchingInvoice} hasLoadedInvoice={hasLoadedInvoice} invoice={invoice} closeModal={this.closeModal} />
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListTable);
