export const USERS = '/users';
export const USERS_DISABLE_2FA = '/users/:id/disable-2fa';
export const USERS_RELATED_TO_INVOICE_BATCH = '/users/related-to-invoice_batch/:id';
export const LOGIN_CHECK = '/login_check';
export const LOGIN_CHECK_2FA = '/2fa/check';
export const LOGIN_SKIP_2FA = '/2fa/skip';
export const REQUEST_2FA_EMAIL = '/2fa/send-email';
export const LOGIN_CHECK_2FA_EMAIL = '/2fa/check-email';
export const FORGOT_PASSWORD = '/forgotten-password/request';
export const RESET_PASSWORD_LINK = '/forgotten-password/admin/request';
export const RESET_PASSWORD = '/forgotten-password/new-password';
export const CHANGE_PASSWORD = '/change-password';

export const INVOICE = '/invoices';
export const INVOICE_SINGLE = '/invoice';
export const INVOICE_SINGLE_BY_UNIQUE_REF = '/invoice-by-unique-ref';

export const INVOICE_CREATE_FROM_PDF = '/invoices/create-from-pdf';
export const INVOICE_UPLOAD_INVOICE_PDF = '/invoices/:id/upload_file';
export const INVOICE_PDF_FILE_DOWNLOAD = '/invoice_pdf_file/:id';
export const INVOICE_UPLOAD = '/invoices/upload';
export const INVOICE_UPLOAD_UPDATE = '/invoices/upload/update';
export const INVOICE_POLL = '/invoices/upload/poll';
export const INVOICE_ASSIGN = '/invoices/bulk_assign';

export const INVOICE_UNASSIGNED = '/invoices/bulk_unassign';

export const INVOICE_PAYOR_TYPE = '/payor_types';

export const INVOICE_MISSING_DETAILS_TYPE = '/invoice_missing_details_types';

export const INVOICE_ACTION_STATS = '/credit-control-chaser-stats';
export const INVOICE_MANUAL_PAYMENT = '/invoices/:id/manual_payment'
export const INVOICE_MANUAL_PHONE_CALL = '/invoices/:id/manual_phone_call';
export const INVOICE_BATCH = '/invoice_batches';
export const INVOICE_BATCH_ASSIGN = '/invoice_batches/assign';
export const INVOICE_BATCH_CHECKED_AND_PRICED_SUB_PATH = '/add_checked_and_priced_files';
export const INVOICE_BATCH_APPEND_CHECKED_AND_PRICED_SUB_PATH = '/append_checked_and_priced_files';
export const INVOICE_BATCH_USE_ORIGINAL_FILES_SUB_PATH = '/use_original_files';
export const INVOICE_BATCH_NOTE = '/invoice_batch_notes';
export const INVOICE_BATCH_NOTES = '/invoice_batches/:id/invoice_batch_notes';
export const INVOICE_BATCH_VALIDATE_NUMBERS = '/invoice_batches/:id/validate_invoice_numbers';
export const INVOICE_BATCH_STATUS = '/invoice_batch_statuses';
export const INVOICE_BATCH_AUDIT_LOG = '/invoice_batches/:id/audit_logs';
export const INVOICE_DATE = '/invoice_dates';
export const INVOICE_BATCH_URGENT_CATEGORY = '/invoice_batch_urgent_categories';
export const INVOICE_BATCH_PROBLEM_CATEGORY = '/invoice_batch_problem_categories';

export const INVOICE_BATCH_WEEKLY_COUNTS = '/invoice-weekly-stats';
export const INVOICE_BATCH_WEEKLY_COUNTS_EXPORT = '/invoice-weekly-stats/export';

export const INVOICE_NUMBERS_EXPORT = '/invoice-export';

export const INVOICE_ESCALATION_REASON = '/invoice_escalation_reasons';
export const INVOICE_ESCALATION_STATUS = '/invoice_escalation_statuses';

export const BILLING_CHECKLIST = '/invoice_batches/weekly';
export const BILLING_CHECKLIST_WEEKLY_EVENTS = '/invoice_batches/weekly_events';
export const WORK_SCHEDULE_WEEKLY_EVENTS = '/work_schedule/weekly-events';
export const BILLING_CHECKLIST_EVENTS = '/billing_checklist_events';
export const WORK_SCHEDULE_EVENTS = '/work_schedule_events';
export const WORK_SCHEDULE_LIST = '/work_schedule/weekly';
export const WORK_SCHEDULE_UPDATE = '/work_schedule/update';

export const CHASER_WORK_SCHEDULE_LIST = '/chaser_work_schedule/weekly';
export const CHASER_WORK_SCHEDULE_LIST_FOR_USER = '/chaser_work_schedule/weekly_for_user';
export const CHASER_WORK_SCHEDULE_UPDATE = '/chaser_work_schedule/update';

export const CONSULTANTS = '/consultants';
export const CONSULTANT_DEACTIVATE = '/consultants/:id/deactivate';
export const CONSULTANT_ACTIVATE = '/consultants/:id/activate';
export const CONSULTANT_TYPE = '/consultant_types';

export const CONSULTANT_TICKETS = '/consultant_tickets';
export const CONSULTANT_TICKET_TYPE = '/consultant_ticket_types';
export const CONSULTANT_TICKET_NOTES = '/consultant_ticket_notes';
export const SUB_USER_BY_CONSULTANT_ID = '/consultant_subuser/for_current_user_by_consultant/:consultantId';
export const SUB_USER_INVITE = '/sub-user/invite';
export const SUB_USER_INVITE_UPDATE = '/sub-user/invite/update';
export const SUB_USER_INVITE_REMOVE = '/sub-user/invite/remove';

export const SUB_USERS = '/consultant_and_subusers_and_staff/:consultantId';

export const SUB_ACCOUNT_INVITE_VERIFY = '/sub-user/verify';
export const SUB_ACCOUNT_INVITE_CONFIRM = '/sub-user/confirm';
export const TEMPORARY_INVOICE_FILES = '/temporary_invoice_files';
export const TEMPORARY_CC_INVOICE_FILES = '/temporary_credit_control_invoice_files';
export const STATS = '/stats';

export const REPORTS = '/reports';
export const REPORT_BATCHES = '/report_batches';
export const REPORT_BATCH_PUBLISH = '/report_batches/:id/publish';
export const REPORT_FILE_DOWNLOAD = '/report/batch/file/:id';

export const HOSPITALS = '/hospitals';

export const CONSULTANT_HEALTH_INSURERS = '/consultant_health_insurers';

export const HEALTH_INSURER = '/health_insurers';
export const CCSD_CODES = '/ccsd_codes';
export const CCSD_CODE_COSTS = '/ccsd_code_costs';
export const CCSD_CODE_TYPE = '/ccsd_code_types';
export const DATED_COSTS = '/dated_costs';

export const CONSULTANT_BIBLE_LOG = '/consultant_bible_audit_logs';

export const CMS_FETCH_REMITTANCES = '/cms_contents/remittances';
export const CMS_FETCH_HOSPITAL = '/cms_contents/hospital';
export const CMS_FETCH_INSURANCE = '/cms_contents/insurance';
export const CMS_FETCH_EMBASSY = '/cms_contents/embassy';

export const CMS_CONTENT_UPDATE = '/cms_contents/:page';
export const CMS_CONTENT_CREATE = '/cms_contents';

export const ONBOARDING_ITEMS = '/onboarding_items?onboardingUser=:id';
export const ONBOARDING_ITEMS_UPDATE = '/onboarding_items';

export const PROBLEM_DATA = '/problem_datas';
export const PROBLEM_DATA_SINGLE = '/problem_datas/:id';
export const PROBLEM_TYPES = '/problem_types';
export const PROBLEM_STATUSES = '/problem_statuses';

export const PROBLEM_DATA_INSURERS = '/problem_data_insurers';

export const UPLIFT_DATA = '/uplift_datas';
export const UPLIFT_DATA_SINGLE = '/uplift_datas/:id';

export const EMAIL_AUDIT_LOG = '/email_audit_logs';

export const SEARCH = '/search';
export const MATCHING_CONTACTS = '/contact-record/find-matching';

export const SITE_SETTINGS = '/site_settings';

export const PAYMENT_LINK = '/worldpay-payment-request/:invoiceId';

export const CONTACT_RECORDS = '/contact_records';
export const CONTACT_RECORD_TYPE = '/contact_record_types';

export const MANUAL_INVOICE_NOTIFICATIONS = '/manual_invoice_notifications';

export const SIMULATE_CHASE_INVOICE = '/simulate-chase-invoice';

export const INVOICE_NOTIFICATION_EVENTS = '/invoice_notification_events';

export const PUBLIC_HEALTH_INSURERS = '/public_health_insurers';
export const ADD_INSURANCE_DETAILS = '/invoice_insurance_details';
export const ACTION_INSURANCE_DETAILS = '/invoice_insurance_details/:id/actioned';
