import React from 'react';
import { invoiceBatchSingleComplete } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { addToast } from '../../lib/redux/actions/toast';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import { isProcessing } from '../../lib/helper/invoiceBatch';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  invoiceBatchSingleComplete,
  addToast,
};

class CompleteButton extends React.Component {
  accept() {
    const { invoiceBatch, invoiceBatchSingleComplete, addToast } = this.props;
    return invoiceBatchSingleComplete(invoiceBatch.id).then(() => {
      addToast('The invoice batch was marked as complete', true);
    });
  }

  render() {
    const { invoiceBatch, userData, buttonText = 'Complete' } = this.props;
//    console.log({
//      isProcessing: isProcessing(invoiceBatch),
//      invoiceNumbersLength: Number(invoiceBatch.invoiceNumbers.length),
//      numberOfInvoicesToProcess: Number(invoiceBatch.numberOfInvoicesToProcess),
//      numberOfRelatedInvoices: invoiceBatch.invoices.length,
//    })
    if (
      isProcessing(invoiceBatch) &&
      ((Number(invoiceBatch.invoiceNumbers.length) === Number(invoiceBatch.numberOfInvoicesToProcess - invoiceBatch.invoices.length) &&
        invoiceBatch.assignedUser &&
        invoiceBatch.assignedUser['@id'] === userData['@id']) ||
        (isSeniorStaff() && Number(invoiceBatch.invoiceNumbers.length) === Number(invoiceBatch.numberOfInvoicesToProcess - invoiceBatch.invoices.length)) ||
        (invoiceBatch.isEmbassy && invoiceBatch.invoiceBatchNotesEmbassyCaseClosedCount > 0))
    ) {
      return (
        <button onClick={this.accept.bind(this)} type="button" className="btn btn-primary">
          <i className="fa fa-check mr-2" />
          {buttonText}
        </button>
      );
    }

    return null;
  }
}

CompleteButton.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  invoiceBatchSingleComplete: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteButton);
