import PropTypes from 'prop-types';
import React from 'react';
import ConsultantListTable from './ConsultantListTable';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { consultantListFetch, consultantListSetPage, consultantsListUnload } from '../../lib/redux/actions/consultant';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import * as ROUTES from '../../lib/routing/frontend';
import ConsultantTableFilterForm from '../../components/forms/Consultant/ConsultantTableFilterForm';
import MASpinner from '../../components/common/MASpinner';
import { getFiltersFromSession } from '../../lib/routing/filters';
import AppliedFiltersOutput from '../../components/common/AppliedFiltersOutput';

const mapStateToProps = (state) => ({
  ...state.consultantList,
});

const mapDispatchToProps = {
  consultantListFetch,
  consultantListSetPage,
  consultantsListUnload,
};

class ConsultantListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
    };
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {}, match } = this.props;
    let filtersFromSession = getFiltersFromSession(match.path, 'json');
    return { ...filters, ...defaultFilters, ...filtersFromSession };
  }

  componentWillUnmount() {
    this.props.consultantsListUnload();
  }

  componentDidMount() {
    this.props.consultantListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  componentDidUpdate(prevProps) {
    const { currentPage, consultantListFetch, consultantListSetPage, filters } = this.props;

    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      consultantListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      consultantListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, consultantListSetPage } = this.props;
    consultantListSetPage(page);
    history.push(ROUTES.CONSULTANTS.ALL.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  render() {
    const { consultants, isFetchingConsultants, currentPage, pageCount, pageTitle } = this.props;

    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            {' '}
            {pageTitle}
            {!isFetchingConsultants && consultants && consultants.length > 0 && (
              <div>
                <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                  Filters
                </button>
                <span className="tabular-data-result-count">{pageCount} pages</span>
              </div>
            )}
          </div>
        </Card.Header>

        {this.state.showFilters && (
          <Card.Body className={'mediaccount-filter-wrapper'}>
            <ConsultantTableFilterForm
                location={this.props.match.path}
            />
          </Card.Body>
        )}

        {isFetchingConsultants && (
          <Card.Body>
            <MASpinner />
          </Card.Body>
        )}

        {!isFetchingConsultants && (consultants === null || consultants.length === 0) && (
          <Card.Body>
            <Alert variant={'info'} className="mb-0">
              No consultants found
            </Alert>
          </Card.Body>
        )}
        <AppliedFiltersOutput/>

        {!isFetchingConsultants && consultants && consultants.length > 0 && <ConsultantListTable consultants={consultants} />}
        {!isFetchingConsultants && consultants && consultants.length > 0 && (
          <Paginator
            currentPage={currentPage}
            pageCount={pageCount}
            setPage={this.changePage.bind(this)}
            nextPage={this.nextPage.bind(this)}
            prevPage={this.prevPage.bind(this)}
          />
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantListContainer);

ConsultantListContainer.propTypes = {
  consultantListFetch: PropTypes.func.isRequired,
  consultantListSetPage: PropTypes.func.isRequired,
  consultants: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  filters: PropTypes.object,
  history: PropTypes.any,
  isFetchingConsultants: PropTypes.bool,
  match: PropTypes.object,
  pageCount: PropTypes.number,
  pageTitle: PropTypes.string,
};
