import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_RECEIVED,
  INVOICE_LIST_ERROR,
  INVOICE_LIST_SET_PAGE,
  INVOICE_LIST_UNLOAD,
  INVOICE_SINGLE_REQUEST,
  INVOICE_SINGLE_RECEIVED,
  INVOICE_SINGLE_ERROR,
  INVOICE_LIST_SET_FILTERS,
  INVOICE_LIST_UNLOAD_PERSIST_FILTERS,
  INVOICE_LIST_CLEAR_FILTERS,
  INVOICE_SINGLE_UPDATE_REQUEST,
  INVOICE_SINGLE_UPDATED,
  INVOICE_SINGLE_UNLOAD,
  INVOICE_DELETE_REQUEST,
  INVOICE_DELETED,
  INVOICE_SINGLE_ACTION_REQUEST,
  INVOICE_SINGLE_ACTION_PERFORMED,
  INVOICE_SINGLE_ACTION_ERROR,
  INVOICE_ADD_REQUEST,
  INVOICE_ADDED,
  INVOICE_ASSIGN_REQUEST,
  INVOICE_ASSIGN_RECEIVED,
  INVOICE_ASSIGN_ERROR,
  INVOICE_UNASSIGN_REQUEST,
  INVOICE_BULK_UNASSIGNED,
  INVOICE_SINGLE_UNASSIGNED_FROM_CURRENT_USER,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceListRequest = () => ({
  type: INVOICE_LIST_REQUEST,
});

export const invoiceListReceived = (data) => ({
  type: INVOICE_LIST_RECEIVED,
  data,
});

export const invoiceListError = (error) => ({
  type: INVOICE_LIST_ERROR,
  error,
});

export const invoiceListSetPage = (page) => ({
  type: INVOICE_LIST_SET_PAGE,
  page,
});

export const invoiceListSetFilters = (filters, routeKey) => ({
  type: INVOICE_LIST_SET_FILTERS,
  filters,
  routeKey
});

export const invoiceListClearFilters = (routeKey) => ({
  type: INVOICE_LIST_CLEAR_FILTERS,
  routeKey
});

export const invoiceListUnload = (persistFilters = true, routeKey) => {
  if (persistFilters) {
    return {
      type: INVOICE_LIST_UNLOAD_PERSIST_FILTERS,
      routeKey
    };
  }
  return {
    type: INVOICE_LIST_UNLOAD,
    routeKey
  };
};

export const invoiceListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(invoiceListRequest());
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    if (filters.pagination) {
      filters.page = page;
    }
    return requests
      .get(API_ROUTES.INVOICE, filters)
      .then((response) => dispatch(invoiceListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceListError(error));
      });
  };
};

// CONSULTANT_SINGLE

export const invoiceSingleRequest = () => ({
  type: INVOICE_SINGLE_REQUEST,
});

export const invoiceSingleReceived = (data) => ({
  type: INVOICE_SINGLE_RECEIVED,
  data,
});

export const invoiceSingleError = (error) => ({
  type: INVOICE_SINGLE_ERROR,
  error,
});

export const invoiceSingleFetch = (id) => {
  return invoiceSingleFetchByIRI(`${API_ROUTES.INVOICE}/${id}`);
  //  return (dispatch) => {
  //    dispatch(invoiceSingleRequest());
  //    return requests
  //      .get()
  //      .then((response) => dispatch(invoiceSingleReceived(response)))
  //      .catch((error) => {
  //        if (error.response && error.response.status === 401) {
  //          return dispatch(userLogout());
  //        } else if (error.response && error.response.status === 403) {
  //          return new invoiceSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
  //        }
  //        dispatch(invoiceSingleError(error));
  //      });
  //  };
};

export const invoiceSingleFetchByIRI = (iri) => {
  //  console.log('invoiceSingleFetchByIRI: ', iri);
  return (dispatch) => {
    dispatch(invoiceSingleRequest());
    return requests
      .get(iri)
      .then((response) => dispatch(invoiceSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new invoiceSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceSingleError(error));
      });
  };
};

export const invoiceSingleFetchByUniqueRef = (uniqueRef, filters) => {
  return (dispatch) => {
    dispatch(invoiceSingleRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_SINGLE}/${uniqueRef}`, filters)
      .then((response) => dispatch(invoiceSingleReceived(response)))
      .catch((error) => {
        //          if (error.response && error.response.status === 403) {
        //            return new invoiceSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        //          }
        if (error && error.response && error.response.body) {
          let invoiceError = error.response.body;
          dispatch(invoiceSingleError({ error: invoiceError }));
        }
      });
  };
};

export const invoiceSingleGetByUniqueRefContactId = (uniqueRef,contactId) => {
  return (dispatch) => {
    dispatch(invoiceSingleRequest());
    return requests
        .get(`${API_ROUTES.INVOICE_SINGLE_BY_UNIQUE_REF}/${contactId}/${uniqueRef}`)
        .then((response) => dispatch(invoiceSingleReceived(response)))
        .catch((error) => {
          if (error && error.response && error.response.body) {
            let invoiceError = error.response.body;
            dispatch(invoiceSingleError({ error: invoiceError }));
          }else {
            dispatch(invoiceSingleError(error));
          }
        });
  };
}

export const invoiceSingleUpdateRequest = () => ({
  type: INVOICE_SINGLE_UPDATE_REQUEST,
});

export const invoiceSingleUpdated = (data) => ({
  type: INVOICE_SINGLE_UPDATED,
  data,
});

export const invoiceSingleUpdate = (data, invoiceId) => {
  return (dispatch) => {
    dispatch(invoiceSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.INVOICE}/${invoiceId}`, data)
      .then((response) => dispatch(invoiceSingleUpdated(response)))
      .catch((error) => {
        dispatch(invoiceSingleError(''));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceSingleUnload = () => ({
  type: INVOICE_SINGLE_UNLOAD,
});

export const invoiceDeleteRequest = () => ({
  type: INVOICE_DELETE_REQUEST,
});

export const invoiceDeleted = (id) => ({
  type: INVOICE_DELETED,
  id,
});

export const invoiceDelete = (invoiceId) => {
  return (dispatch) => {
    dispatch(invoiceDeleteRequest());
    return requests
      .delete(`${API_ROUTES.INVOICE}/${invoiceId}`)
      .then(() => dispatch(invoiceDeleted(invoiceId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceAddRequest = () => ({
  type: INVOICE_ADD_REQUEST,
});

export const invoiceAdded = (data) => ({
  type: INVOICE_ADDED,
  data,
});

export const invoiceUpload = (data) => {
  return (dispatch) => {
    dispatch(invoiceAddRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_UPLOAD}`, data)
      .then((response) => {
        dispatch(invoiceAdded(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceUploadPaid = (data) => {
  return (dispatch) => {
    dispatch(invoiceAddRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_UPLOAD_UPDATE}`, data)
      .then((response) => {
        dispatch(invoiceAdded(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoicePoll = (isUpdate = false) => {
  return (dispatch) => {
    dispatch(invoiceAddRequest());
    return requests
      .get(`${API_ROUTES.INVOICE_POLL}${isUpdate ? '?update=true' : ''}`)
      .then((response) => {
        dispatch(invoiceAdded(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceUnassignRequest = () => ({
  type: INVOICE_UNASSIGN_REQUEST,
});

export const invoicesUnassigned = (data) => ({
  type: INVOICE_BULK_UNASSIGNED,
  data,
});

export const invoiceUnassign = () => {
  return (dispatch) => {
    dispatch(invoiceUnassignRequest());
    return requests
      .get(`${API_ROUTES.INVOICE_UNASSIGNED}`)
      .then((response) => {
        dispatch(invoicesUnassigned(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceSingleActionRequest = () => ({
  type: INVOICE_SINGLE_ACTION_REQUEST,
});

export const invoiceSingleActionPerformed = (data, id) => ({
  type: INVOICE_SINGLE_ACTION_PERFORMED,
  data,
  id,
});

export const invoiceSingleUnassignedFromCurrentUser = (data, id) => ({
  type: INVOICE_SINGLE_UNASSIGNED_FROM_CURRENT_USER,
  data,
  id,
});

export const invoiceSingleActionError = (error) => ({
  type: INVOICE_SINGLE_ACTION_ERROR,
  error,
});

export const invoiceSingleAction = (invoiceId, endpointPart, unassignsCurrentUser = false, body = {}) => {
  return (dispatch) => {
    dispatch(invoiceSingleActionRequest());
    return requests
      .patch(`${API_ROUTES.INVOICE}/${invoiceId}/${endpointPart}`, body)
      .then((response) => {
        if (unassignsCurrentUser) {
          dispatch(invoiceSingleUnassignedFromCurrentUser(response, invoiceId));
        } else {
          dispatch(invoiceSingleActionPerformed(response, invoiceId));
        }
      })
      .catch((error) => {
        dispatch(invoiceSingleActionError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(error);
      });
  };
};

export const invoiceSingleUnlinkFromBatch = (invoiceId) => {
  return invoiceSingleAction(invoiceId, 'unlink');
};

export const invoiceSingleCreateEscalatedAction = (invoiceId, unassignsCurrentUser = false, invoiceEscalationReason) => {
  return invoiceSingleAction(invoiceId, 'escalated', unassignsCurrentUser, { invoiceEscalationReason });
};

export const invoiceSingleCreateMissingPhoneContactDetailsAction = (invoiceId, unassignsCurrentUser = false) => {
  return invoiceSingleAction(invoiceId, 'missing_contact_details_phone', unassignsCurrentUser);
};

export const invoiceSingleCreateMissingEmailContactDetailsAction = (invoiceId, unassignsCurrentUser = false) => {
  return invoiceSingleAction(invoiceId, 'missing_contact_details_email', unassignsCurrentUser);
};

export const invoiceSingleCreateMissingPhoneEmailContactDetailsAction = (invoiceId, unassignsCurrentUser = false) => {
  return invoiceSingleAction(invoiceId, 'missing_contact_details_phone_email', unassignsCurrentUser);
};

export const invoiceSingleCreateCompleteCallAction = (invoiceId, unassignsCurrentUser = false) => {
  return invoiceSingleAction(invoiceId, 'complete_call', unassignsCurrentUser);
};

export const invoiceAssignRequest = () => ({
  type: INVOICE_ASSIGN_REQUEST,
});

export const invoiceAssignReceived = (data) => ({
  type: INVOICE_ASSIGN_RECEIVED,
  data,
});

export const invoiceAssignError = (error) => ({
  type: INVOICE_ASSIGN_ERROR,
  error,
});

export const invoiceAssign = (data) => {
  return (dispatch) => {
    dispatch(invoiceAssignRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_ASSIGN}`, data)
      .then((response) => {
        dispatch(invoiceAssignReceived(response));
      })
      .catch((error) => {
        dispatch(invoiceAssignError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(error);
      });
  };
};
