import React from "react";
import type {Invoice} from "../../apiTypes";
import {invoiceSingleUnlinkFromBatch} from "../../lib/redux/actions/invoices";
import {connect} from "react-redux";
import {addToast} from "../../lib/redux/actions/toast";
import {isSeniorStaff} from "../../lib/helper/authorisation";

const mapDispatchToProps = {
    invoiceSingleUnlinkFromBatch,
    addToast
};

interface Props {
    invoice: Invoice;
    invoiceSingleUnlinkFromBatch: (invoiceId: string|number) => Promise<string>;
    addToast: (message: string, isSuccess: boolean) => void;
}

const UnlinkInvoiceFromBatchButton = ({invoice, invoiceSingleUnlinkFromBatch, addToast}: Props) => {
    if (invoice.id === undefined || !isSeniorStaff()) {
        return null;
    }
    const unlinkInvoice = () => {
        window.confirm('Are you sure you want to unlink this invoice from its batch?') && invoiceSingleUnlinkFromBatch(Number(invoice.id)).then(() => {
            addToast('Invoice unlinked from batch', true );
        });
    }
    return <button type={'button'} className={'btn btn-xs btn-danger-outline'} onClick={()=>unlinkInvoice()}>
        <i className={'fa fa-unlink'} title={'Unlink Invoice From Batch'}/>
    </button>
}


export default connect(null,mapDispatchToProps)(UnlinkInvoiceFromBatchButton);
