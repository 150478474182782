import { INSURANCE_DETAILS_REQUEST, INSURANCE_DETAILS_UPDATED } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';

export const insuranceDetailsRequest = () => ({
  type: INSURANCE_DETAILS_REQUEST,
});

export const insuranceDetailsUpdated = data => ({
  type: INSURANCE_DETAILS_UPDATED,
  data,
});

export const addInsurerDetails = (formValues) => {
  return (dispatch) => {
    dispatch(insuranceDetailsRequest());
    return requests.post(API_ROUTES.ADD_INSURANCE_DETAILS, formValues)
        .then(response => dispatch(insuranceDetailsUpdated(response)))
        .catch(error => {
          if (error && error.response && error.response.status === 401) {

          }
          throw new SubmissionError(parseApiErrors(error));
        });
  };
};


export const invoiceInsuranceDetailsActioned = (id)=>{
  return (dispatch) => {
    dispatch(insuranceDetailsRequest());
    return requests.post(API_ROUTES.ACTION_INSURANCE_DETAILS.replace(':id',id), {})
//        .then(response => dispatch(insuranceDetailsUpdated(response)))
        .catch(error => {
          if (error && error.response && error.response.status === 401) {

          }
          throw new SubmissionError(parseApiErrors(error));
        });
  };

}
