import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UpdateInsuranceDetailsForm from '../../components/forms/InsuranceDetails/UpdateInsuranceDetailsForm';
import { fetchPublicHealthInsurers } from '../../lib/redux/actions/publicHealthInsurers';
import { invoiceSingleGetByUniqueRefContactId } from '../../lib/redux/actions/invoices';
import { useHistory, useParams } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';

function UpdateInsuranceDetailsContainer({ fetchPublicHealthInsurers, invoice, invoiceSingleGetByUniqueRefContactId, isFetchingInvoice, isFetchingPublicHealthInsurers }) {
  const { uniqueRef, contactId } = useParams();
  let history = useHistory();
  const [loadingStarted, setLoadingStarted] = useState(false);
  const [hasConfirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, []);

  useEffect(() => {
    fetchPublicHealthInsurers();
    invoiceSingleGetByUniqueRefContactId(uniqueRef, contactId);
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, [fetchPublicHealthInsurers, invoiceSingleGetByUniqueRefContactId, uniqueRef, contactId]);

  useEffect(() => {
    if (!loadingStarted && isFetchingInvoice) {
      setLoadingStarted(true);
    }
    if (loadingStarted && !isFetchingInvoice && invoice === null) {
      history.push(ROUTES.NOT_FOUND);
    }
  }, [isFetchingInvoice, loadingStarted, invoice, history]);

  return <Container>
    <Row className="mt-4">
      <Col>
        {isFetchingPublicHealthInsurers || isFetchingInvoice ? <p>Loading ...</p> : null}
        {!isFetchingInvoice && !isFetchingPublicHealthInsurers && !hasConfirmed ? (
            <div className="patient-invoice card text-center">
              <div className="card-header">
                <h2>PLEASE READ BEFORE PROCEEDING</h2>
              </div>
              <div className="card-body">
                <h3>Updating your Insurance Details</h3>
                <br/>
                <p>If you provided insurance details at the time then this invoice has already been submitted and assessed as patient liability.
                  Please read the attached invoice before submitting insurance details again.</p>
                <br/>
                <div>
                  <button type="button" onClick={() => setConfirmed(true)} className="btn-primary text-capitalize btn btn-primary">I understand and still need to update my details</button>
                </div>
              </div>
            </div>
        ) : null}
        {!isFetchingInvoice && !isFetchingPublicHealthInsurers && hasConfirmed ? <UpdateInsuranceDetailsForm/> : null}

      </Col>
    </Row>
  </Container>;
};

const mapStateToProps = (state) => ({
  ...state.invoices,
  ...state.publicHealthInsurers,
});

const mapDispatchToProps = {
  fetchPublicHealthInsurers,
  invoiceSingleGetByUniqueRefContactId,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateInsuranceDetailsContainer);


