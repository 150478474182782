import { PUBLIC_HEALTH_INSURERS_ERROR, PUBLIC_HEALTH_INSURERS_RECEIVED, PUBLIC_HEALTH_INSURERS_REQUEST } from '../constants';

const reducer = (
    state = {
      publicHealthInsurers: [],
      isFetchingPublicHealthInsurers: false,
    },
    action,
) => {
  switch (action.type) {
    case PUBLIC_HEALTH_INSURERS_REQUEST:
      return {
        ...state,
        isFetchingPublicHealthInsurers: true,
      };
    case PUBLIC_HEALTH_INSURERS_RECEIVED:
      return {
        ...state,
        isFetchingPublicHealthInsurers: false,
        publicHealthInsurers: action.data['hydra:member'],
      };
    case PUBLIC_HEALTH_INSURERS_ERROR:
      return {
        ...state,
        isFetchingPublicHealthInsurers: false,
        publicHealthInsurers: null,
      };
    default:
      return state;
  }
};

export default reducer;
