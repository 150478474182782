import React from 'react';
import Form from 'react-bootstrap/Form';
import {Field, reduxForm} from 'redux-form';
import {renderCheckbox, renderSelect} from 'lib/helper/form';
import MASpinner from '../../common/MASpinner';
import {connect} from 'react-redux';
import {addToast} from 'lib/redux/actions/toast';
import {requests} from '../../../lib/helper/agent';
import * as API_ROUTES from '../../../lib/routing/api';
import {invoiceSingleFetch} from "../../../lib/redux/actions/invoices";

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = {
    addToast,
    invoiceSingleFetch
};

class ManualInvoiceNotificationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manualNotifications: [],
        };
    }

    componentDidMount() {
        const setNotifications = (manualNotifications) => {
            this.setState({manualNotifications});
        };
        requests.get(API_ROUTES.MANUAL_INVOICE_NOTIFICATIONS).then((response) => {
            setNotifications(response['hydra:member']);
        });
    }

    onSubmit(values) {
        const {addToast, reset, invoice, invoiceSingleFetch} = this.props;

        const data = {
            invoice_id: Number(invoice.id),
            contact_ids: invoice.contactRecords.filter((c) => values['contact-' + c.id]).map((c) => c.id),
            methods: Object.keys(values)
                    .map((k) => (k.includes('method-') ? k.replace('method-', '').toLowerCase() : null))
                    .filter((k) => k),
        };

        return requests.patch(API_ROUTES.MANUAL_INVOICE_NOTIFICATIONS + '/' + values.notification + '/send', data).then((response) => {
            addToast('The Invoice was successfully updated', true);
            reset();
            invoiceSingleFetch(Number(invoice.id));
        });
    }

    render() {
        const {handleSubmit, isUpdatingInvoice = false, submitting, pristine, invoice, reset} = this.props;
        const notificationOptions = this.state.manualNotifications.map((manualNotification) => ({value: manualNotification.id, text: manualNotification.name}));

        return (
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <h5>Manually Send Notification</h5>
                    <div className="mt-3 row">
                        <div className="col">
                            <p className={'mb-2'}>Recipients</p>
                            {invoice?.contactRecords &&
                                    invoice.contactRecords
                                            .filter((c) => c.notifyByEmail || c.notifyByMobile)
                                            .map((c) => (
                                                    <div className="mb-1" key={c.name+ '-' +c.id}>
                                                        <Field name={'contact-' + c.id} id={c.name+ '-' +c.id} label={c.name + ` (${c.contactRecordType.name})`} component={renderCheckbox}/>
                                                    </div>
                                            ))}
                        </div>


                        <div className="col">
                            <p className={'mb-2'}>Method</p>
                            {['Email', 'WhatsApp'].map((m) => (
                                    <div className="mb-1" key={m}>
                                        <Field id={m} name={'method-' + m} label={m} component={renderCheckbox}/>
                                    </div>
                            ))}
                        </div>
                    </div>
                    <Field name="notification" label="Notification to send" type="select" emptyLabel={''} options={notificationOptions} component={renderSelect} helpText={<small>Contacts will be notified by their configured options</small>}/>
                    {isUpdatingInvoice ? <MASpinner/> : null}
                    {!isUpdatingInvoice && !pristine ? (
                            <>
                                <button type="submit" disabled={submitting || pristine} className="btn btn-primary mr-2">
                                    Send Notification
                                </button>
                                <button type="reset" disabled={submitting} className="btn btn-secondary" onClick={() => reset()}>
                                    Reset
                                </button>
                            </>
                    ) : null}
                </Form>
        );
    }
}

export default connect(
        mapStateToProps,
        mapDispatchToProps,
)(
        reduxForm({
            form: 'ManualInvoiceNotificationForm',
            enableReinitialize: true,
            destroyOnUnmount: false,
        })(ManualInvoiceNotificationForm),
);
