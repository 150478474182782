import React from 'react';
import UserListTable from './UserListTable';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { userListFetch, userListSetPage } from '../../lib/redux/actions/userList';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import * as ROUTES from '../../lib/routing/frontend';
import UserTableFilterForm from '../../components/forms/User/UserTableFilterForm';
import MASpinner from '../../components/common/MASpinner';
import AppliedFiltersOutput from '../../components/common/AppliedFiltersOutput';

const mapStateToProps = (state) => ({
  ...state.userList,
});

const mapDispatchToProps = {
  userListFetch,
  userListSetPage,
};

class UserListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
    };
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    return { ...filters, ...defaultFilters };
  }

  componentDidMount() {
    this.props.userListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  componentDidUpdate(prevProps) {
    const { currentPage, userListFetch, userListSetPage, filters } = this.props;

    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      userListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      userListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, userListSetPage } = this.props;
    userListSetPage(page);
    history.push(ROUTES.USERS.ALL.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  render() {
    const { users, isFetchingUsers, currentPage, pageCount, pageTitle } = this.props;

    return (
        <div>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between">
                {' '}
                {pageTitle}
                {!isFetchingUsers && users && users.length > 0 && (
                    <div>
                      <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                        Filters
                      </button>
                      <span className="tabular-data-result-count">{pageCount} pages</span>
                    </div>
                )}
              </div>
            </Card.Header>

            {this.state.showFilters && (
                <Card.Body className={'mediaccount-filter-wrapper'}>
                  <UserTableFilterForm
                      location={this.props.match.path}
                  />
                </Card.Body>
            )}

            {isFetchingUsers && (
                <Card.Body>
                  <MASpinner/>
                </Card.Body>
            )}

            {!isFetchingUsers && (users === null || users.length === 0) && (
                <Card.Body>
                  <Alert variant={'info'} className="mb-0">
                    No users found
                  </Alert>
                </Card.Body>
            )}
            <AppliedFiltersOutput/>

            {!isFetchingUsers && users && users.length > 0 && <UserListTable users={users}/>}
            {!isFetchingUsers && users && users.length > 0 && (
                <Paginator
                    currentPage={currentPage}
                    pageCount={pageCount}
                    setPage={this.changePage.bind(this)}
                    nextPage={this.nextPage.bind(this)}
                    prevPage={this.prevPage.bind(this)}
                />
            )}
          </Card>
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
