import Card from 'react-bootstrap/Card';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { change, Field, Form, reduxForm, SubmissionError } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {  renderField, renderSelect } from '../../../lib/helper/form';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PUBLIC_INSURANCE_DETAILS } from '../../../lib/routing/frontend';
import { addInsurerDetails } from '../../../lib/redux/actions/insuranceDetails';
import moment from 'moment';

function UpdateInsuranceDetailsForm({ handleSubmit, invoice, dispatch, publicHealthInsurers = [], addInsurerDetails }) {
  const history = useHistory();
  const [formState, setFormState] = useState({
    formDisabled: false,
    validationMessage: '',
    authCodeRequired: false,
  });
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (invoice) {
      dispatch(change('UpdateInsuranceDetailsForm', 'patientName', `${invoice.patientFirstName} ${invoice.patientLastName}`));
      dispatch(change('UpdateInsuranceDetailsForm', 'dateOfBirth', moment(invoice.dateOfBirth).format('YYYY-MM-DD')));
      dispatch(change('UpdateInsuranceDetailsForm', 'invoiceNo', invoice.invoiceNo));
      dispatch(change('UpdateInsuranceDetailsForm', 'invoice', '/invoices/' + invoice.id));
      const contact = invoice?.contactRecords ? Object.values(invoice.contactRecords)[0] : false;
      if (contact) {
        dispatch(change('UpdateInsuranceDetailsForm', 'requestedBy', contact.name));
        dispatch(change('UpdateInsuranceDetailsForm', 'contactEmail', contact.email));
        dispatch(change('UpdateInsuranceDetailsForm', 'contactPhone', contact.mobileNumber));
      }
    }
  }, [invoice, dispatch]);

  const onInsurerChange = useCallback(
      (event, newValue) => {
        const selectedInsurer = publicHealthInsurers.find(insurer => insurer['@id'] === newValue);
        if (selectedInsurer) {
          // If the insurer is not accepted, disable the form and show a validation message
          if (!selectedInsurer.isAccepted) {
            setFormState({
              formDisabled: true,
              validationMessage:
                  'Please note the doctor does not accept this insurance company, settle as a self-pay patient and we will send you a receipt so you can get reimbursed directly',
              authCodeRequired: selectedInsurer.requiresAuthCode,
            });
          } else {
            setFormState({
              formDisabled: false,
              validationMessage: '',
              authCodeRequired: selectedInsurer.requiresAuthCode,
            });
          }

          // If the selected insurer has a `membershipPrefix`, pre-fill the membership number
          if (selectedInsurer.membershipPrefix) {
            dispatch(change('UpdateInsuranceDetailsForm', 'membershipNumber', selectedInsurer.membershipPrefix));
          } else {
            // Clear the field if there's no prefix for the selected insurer
            dispatch(change('UpdateInsuranceDetailsForm', 'membershipNumber', ''));
          }
        }
      },
      [publicHealthInsurers, dispatch],
  );

  const onSubmit = formValues => {
    try {
      const contact = invoice?.contactRecords ? Object.values(invoice.contactRecords)[0] : false;
      if ( !contact ) {
        throw new SubmissionError({error:'An error occurred please refresh and try again'});
      }
//      delete formValues.invoiceNo;
      formValues.invoice = '/invoices/' + invoice.id;
      formValues.contactRecord = '/contact_records/' + contact.id;
      return addInsurerDetails(formValues).then(() => {
        history.push(PUBLIC_INSURANCE_DETAILS.SUCCESS);
      });
    } catch (e) {
      setSuccessMessage(null);
//      console.error(e);
    }
  };

  const getInsurersOptions = useMemo(() => {
    return publicHealthInsurers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(insurer => ({
          key: insurer.id,
          value: insurer['@id'],
          text: insurer.name,
        }));
  }, [publicHealthInsurers]);

  const { formDisabled, validationMessage, authCodeRequired } = formState;

  return <Card className="patient-invoice">
    <Card.Header>
      <h5>Update Insurance Details</h5>
    </Card.Header>
    <Card.Body>
      {successMessage ? <Alert variant={'success'}>{successMessage}</Alert> : (
          <>
            <p>Please update your insurance details below.</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={formDisabled}>
                <Row className={'text-left'}>
                  <Col>
                    <Field name="invoiceNo" label="Invoice Number" required={true} component={renderField} disabled={true}/>
                  </Col>
                </Row>
                <Row className={'text-left row'}>
                  <Col sm={12} lg={6}>
                    <Field name="patientName" label="Patient Name" required={true} component={renderField}/>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Field name="dateOfBirth" label="Patient Date of Birth" type={'date'} component={renderField}/>
                  </Col>
                </Row>
                <Row className={'text-left'}>
                  <Col>
                    <Field name="requestedBy" label="Your name (if different from patient)" component={renderField}/>
                  </Col>
                </Row>
                <Row className={'text-left'}>
                  <Col sm={12} lg={6}>
                  <Field name="contactPhone" type="text" label="A contact phone number" component={renderField}/>
                </Col>
                  <Col sm={12} lg={6}>
                    <Field name="contactEmail" type="text" label="A contact email address" component={renderField}/>
                  </Col>
                </Row>

              </fieldset>
              <Row className={'text-left'}>
                <Col>
                  <Field name="publicHealthInsurer" label="Insurer" required={true} options={getInsurersOptions} emptyLabel={'Please select'} component={renderSelect} onChange={onInsurerChange}/>
                </Col>
              </Row>
              <fieldset disabled={formDisabled}>
                <Row className={'text-left'}>
                  <Col>
                    <Field name="membershipNumber" label="Membership Number" required={true}  component={renderField}/>
                  </Col>
                </Row>
                <Row className={'text-left'}>
                  <Col>
                    <Field
                        name="authCode"
                        label="Authorization code"
                        component={renderField}
                        required={authCodeRequired}
                    />
                  </Col>
                </Row>
              </fieldset>
              {!validationMessage && (
                  <div className="text-center">
                    <Button type="submit" className="btn-primary text-capitalize">
                      Submit
                    </Button>
                  </div>
              )}
              <div className="mt-2">
                {validationMessage && (
                    <Row>
                      <Col>
                        <Alert variant="danger">{validationMessage}</Alert>
                      </Col>
                    </Row>
                )}
              </div>
            </Form>
          </>
      )}
    </Card.Body>
  </Card>;
}

const mapStateToProps = (state) => {
  return {
    ...state.invoices,
    ...state.publicHealthInsurers,
  };
};

const mapDispatchToProps = {
  addInsurerDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)
(reduxForm({
  form: 'UpdateInsuranceDetailsForm',
})(UpdateInsuranceDetailsForm));
