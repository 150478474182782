import * as API_ROUTES from '../../routing/api';
import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';
import { PUBLIC_HEALTH_INSURERS_ERROR, PUBLIC_HEALTH_INSURERS_RECEIVED, PUBLIC_HEALTH_INSURERS_REQUEST } from '../constants';

export const publicHealthInsurersRequest = () => ({
  type: PUBLIC_HEALTH_INSURERS_REQUEST,
});

export const publicHealthInsurersReceived = data => ({
  type: PUBLIC_HEALTH_INSURERS_RECEIVED,
  data,
});

export const publicHealthInsurersError = error => ({
  type: PUBLIC_HEALTH_INSURERS_ERROR,
  error,
});

export const fetchPublicHealthInsurers = () => {
  return (dispatch) => {
    dispatch(publicHealthInsurersRequest());
    return requests.get(API_ROUTES.PUBLIC_HEALTH_INSURERS)
        .then(response => dispatch(publicHealthInsurersReceived(response)))
        .catch(error => {
          if (error && error.response && error.response.body) {
            dispatch(publicHealthInsurersError({ error: error.response.body }));
          }
          dispatch(publicHealthInsurersError(error));
          throw new SubmissionError(parseApiErrors(error));
        });
  };
};
