import {
  CONSULTANTS_LIST_REQUEST,
  CONSULTANTS_LIST_RECEIVED,
  CONSULTANTS_LIST_ERROR,
  CONSULTANTS_LIST_CLEAR_FILTERS,
  CONSULTANTS_LIST_SET_FILTERS,
  CONSULTANTS_LIST_SET_PAGE,
  CONSULTANT_SINGLE_REQUEST,
  CONSULTANT_SINGLE_RECEIVED,
  CONSULTANT_SINGLE_ERROR,
  CONSULTANT_SINGLE_UPDATE_REQUEST,
  CONSULTANT_SINGLE_UPDATED,
  CONSULTANTS_LIST_UNLOAD,
  CONSULTANT_SUB_ACCOUNT_INVITE_REQUEST,
  CONSULTANT_SUB_ACCOUNT_INVITE_INVITED,
  CONSULTANT_SUB_ACCOUNT_INVITE_UPDATED,
  CONSULTANT_SUB_ACCOUNT_CREATE_REQUEST,
  CONSULTANT_SUB_ACCOUNT_CREATED,
  CONSULTANT_SUB_ACCOUNT_INVITE_ERROR,
  CONSULTANT_SUB_ACCOUNT_INVITE_REMOVED,
  CONSULTANT_SINGLE_CREATE_REQUEST,
  CONSULTANT_SINGLE_CREATE_SUCCESS,
  CONSULTANT_SINGLE_UNLOAD,
  CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_REQUEST,
  CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_SUCCESS,
  CONSULTANT_SINGLE_DELETE_SUCCESS,
  CONSULTANT_SINGLE_DELETE_REQUEST,
  CONSULTANT_REGISTRATION_FORM_SUCCESS,
  CONSULTANT_REGISTRATION_FORM_REQUEST,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantListRequest = () => ({
  type: CONSULTANTS_LIST_REQUEST,
});

export const consultantListReceived = (data) => ({
  type: CONSULTANTS_LIST_RECEIVED,
  data,
});

export const consultantListError = (error) => ({
  type: CONSULTANTS_LIST_ERROR,
  error,
});

export const consultantListSetPage = (page) => ({
  type: CONSULTANTS_LIST_SET_PAGE,
  page,
});

export const consultantListSetFilters = (filters, routeKey) => ({
  type: CONSULTANTS_LIST_SET_FILTERS,
  filters,
  routeKey
});

export const consultantListClearFilters = (routeKey) => ({
  type: CONSULTANTS_LIST_CLEAR_FILTERS,
  routeKey
});

export const consultantListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(consultantListRequest());
    //console.log('Fetching consultants ...');
    filters.page = page;

    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(`${API_ROUTES.CONSULTANTS}`, filters)
      .then((response) => dispatch(consultantListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantListError(error));
      });
  };
};

export const consultantsListUnload = () => ({
  type: CONSULTANTS_LIST_UNLOAD,
});

// CONSULTANT_SINGLE

export const consultantSingleRequest = (isRefresh) => ({
  type: CONSULTANT_SINGLE_REQUEST,
  isRefresh,
});

export const consultantSingleReceived = (data) => ({
  type: CONSULTANT_SINGLE_RECEIVED,
  data,
});

export const consultantSingleError = (error) => ({
  type: CONSULTANT_SINGLE_ERROR,
  error,
});

export const consultantSingleFetch = (id, isRefresh = false, allData = false) => {
  return (dispatch) => {
    dispatch(consultantSingleRequest(isRefresh));
    //console.log('Fetching single consultant ...');
    return requests
      .get(`${API_ROUTES.CONSULTANTS}${allData ? '-full' : ''}/${id}`)
      .then((response) => dispatch(consultantSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new consultantSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantSingleError(error));
      });
  };
};

export const consultantSingleFetchByIRI = (iri) => {
  return (dispatch) => {
    dispatch(consultantSingleRequest());
    //console.log('Fetching single consultant ...');
    return requests
      .get(iri)
      .then((response) => dispatch(consultantSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new consultantSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantSingleError(error));
      });
  };
};

export const consultantSingleUpdateRequest = () => ({
  type: CONSULTANT_SINGLE_UPDATE_REQUEST,
});

export const consultantSingleUpdated = (data) => ({
  type: CONSULTANT_SINGLE_UPDATED,
  data,
});

export const consultantSingleUpdate = (data, consultantId) => {
  return (dispatch) => {
    dispatch(consultantSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.CONSULTANTS}/${consultantId}`, data)
      .then((response) => {
        dispatch(consultantSingleUpdated(response));
        return response;
      })
      .catch((error) => {
        dispatch(consultantSingleError(''));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantSingleUnload = () => ({
  type: CONSULTANT_SINGLE_UNLOAD,
});

export const consultantSingleCreateRequest = () => ({
  type: CONSULTANT_SINGLE_CREATE_REQUEST,
});

export const consultantSingleCreateSuccess = (data) => ({
  type: CONSULTANT_SINGLE_CREATE_SUCCESS,
  data,
});

export const consultantSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(consultantSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.CONSULTANTS}`, data)
      .then((response) => {
        dispatch(consultantSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantSubAccountInviteRequest = () => ({
  type: CONSULTANT_SUB_ACCOUNT_INVITE_REQUEST,
});

export const consultantSubAccountInviteInvited = (data) => ({
  type: CONSULTANT_SUB_ACCOUNT_INVITE_INVITED,
  data,
});

export const consultantSubAccountInviteUpdated = (data) => ({
  type: CONSULTANT_SUB_ACCOUNT_INVITE_UPDATED,
  data,
});

export const consultantSubAccountInviteRemoved = (data) => ({
  type: CONSULTANT_SUB_ACCOUNT_INVITE_REMOVED,
  data,
});

export const consultantSubAccountInviteError = (error) => ({
  type: CONSULTANT_SUB_ACCOUNT_INVITE_ERROR,
  error,
});

export const consultantSubAccountInvite = (data) => {
  return (dispatch) => {
    dispatch(consultantSubAccountInviteRequest());
    return requests
      .post(`${API_ROUTES.SUB_USER_INVITE}`, data)
      .then((response) => dispatch(consultantSubAccountInviteInvited(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          dispatch(consultantSubAccountInviteError(parseApiErrors({ _error: 'You are not allowed to do this.' })));
        }
        dispatch(consultantSubAccountInviteError(parseApiErrors(error)));
      });
  };
};

export const consultantSubAccountInviteUpdate = (data) => {
  return (dispatch) => {
    dispatch(consultantSubAccountInviteRequest());
    return requests
      .post(`${API_ROUTES.SUB_USER_INVITE_UPDATE}`, data)
      .then((response) => dispatch(consultantSubAccountInviteUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          dispatch(consultantSubAccountInviteError(parseApiErrors({ _error: 'You are not allowed to do this.' })));
        }
        dispatch(consultantSubAccountInviteError(parseApiErrors(error)));
      });
  };
};
export const consultantSubAccountInviteRemove = (data) => {
  return (dispatch) => {
    dispatch(consultantSubAccountInviteRequest());
    return requests
      .post(`${API_ROUTES.SUB_USER_INVITE_REMOVE}`, data)
      .then((response) => dispatch(consultantSubAccountInviteRemoved(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          dispatch(consultantSubAccountInviteError(parseApiErrors({ _error: 'You are not allowed to do this.' })));
        }
        dispatch(consultantSubAccountInviteError(parseApiErrors(error)));
      });
  };
};

export const consultantSubAccountCreateRequest = () => ({
  type: CONSULTANT_SUB_ACCOUNT_CREATE_REQUEST,
});

export const consultantSubAccountCreated = (data) => ({
  type: CONSULTANT_SUB_ACCOUNT_CREATED,
  data,
});

export const consultantSubAccountCreate = (data) => {
  return (dispatch) => {
    dispatch(consultantSubAccountCreateRequest());
    return requests
      .post(`${API_ROUTES.SUB_ACCOUNT_INVITE_CONFIRM}`, data)
      .then((response) => dispatch(consultantSubAccountCreated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          throw new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantSingleIsActiveRequest = () => ({
  type: CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_REQUEST,
});

export const consultantSingleIsActiveChangedSuccess = (data) => ({
  type: CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_SUCCESS,
  data,
});

export const consultantSingleDeactivate = (consultantId) => {
  //console.log('Attempting to deactivate consultant ', consultantId);
  return (dispatch) => {
    dispatch(consultantSingleIsActiveRequest());
    return requests
      .patch(API_ROUTES.CONSULTANT_DEACTIVATE.replace(':id', consultantId), {})
      .then((response) => dispatch(consultantSingleIsActiveChangedSuccess(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantSingleActivate = (consultantId) => {
  //console.log('Attempting to activate consultant ', consultantId);
  return (dispatch) => {
    dispatch(consultantSingleIsActiveRequest());
    return requests
      .patch(API_ROUTES.CONSULTANT_ACTIVATE.replace(':id', consultantId), {})
      .then((response) => dispatch(consultantSingleIsActiveChangedSuccess(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantSingleDeleteRequest = () => ({
  type: CONSULTANT_SINGLE_DELETE_REQUEST,
});

export const consultantSingleDeleteSuccess = (data) => ({
  type: CONSULTANT_SINGLE_DELETE_SUCCESS,
  data,
});

export const consultantSingleDelete = (consultantId) => {
  //console.log('Attempting to delete consultant ', consultantId);
  return (dispatch) => {
    dispatch(consultantSingleDeleteRequest());
    return requests
      .delete(`${API_ROUTES.CONSULTANTS}/${consultantId}`)
      .then((response) => dispatch(consultantSingleDeleteSuccess(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantRegistrationFormRequest = () => ({
  type: CONSULTANT_REGISTRATION_FORM_REQUEST,
});

export const consultantRegistrationFormSuccess = (data) => ({
  type: CONSULTANT_REGISTRATION_FORM_SUCCESS,
  data,
});

export const consultantRegistrationFormUpload = (consultantId, file) => {
  //console.log('Attempting to delete consultant ', consultantId);
  let formData = new FormData();
  formData.append('file', file);

  return (dispatch) => {
    dispatch(consultantRegistrationFormRequest());
    return requests
      .post(`${API_ROUTES.CONSULTANTS}/${consultantId}/registration-form`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => dispatch(consultantRegistrationFormSuccess(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
