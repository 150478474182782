import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

function InsuranceDetailsSuccess() {
  return <Container>
    <Row className="mt-4">
      <Col>
        <Card className="patient-invoice">
          <Card.Header>
            <h5>Update Insurance Details</h5>
          </Card.Header>
          <Card.Body>
            <Alert variant="primary" className={'text-center'}>Thank you for updating your details. We will process these details and update the Invoice or get in touch</Alert>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>;
}

export default InsuranceDetailsSuccess;
