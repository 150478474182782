import React from "react";
import type {InvoiceInsuranceDetails} from "../../apiTypes";
import {invoiceInsuranceDetailsActioned} from "../../lib/redux/actions/insuranceDetails";
import {connect} from "react-redux";
import {addToast} from "../../lib/redux/actions/toast";
import {isSeniorStaff} from "../../lib/helper/authorisation";

const mapDispatchToProps = {
    invoiceInsuranceDetailsActioned,
    addToast
};

interface Props {
    invoiceInsuranceDetails: InvoiceInsuranceDetails;
    invoiceInsuranceDetailsActioned: (id: string|number) => Promise<string>;
    addToast: (message: string, isSuccess: boolean) => void;
    reload?: ()=> void;
}

const MarkActionedButton = ({invoiceInsuranceDetails, invoiceInsuranceDetailsActioned, addToast, reload}: Props) => {
    if (invoiceInsuranceDetails.id === undefined || !isSeniorStaff()) {
        return null;
    }
    const setActioned = () => {
        return invoiceInsuranceDetailsActioned(Number(invoiceInsuranceDetails.id)).then(() => {
            addToast('Insurance Update Actioned', true );
            return reload ? reload() : null;
        });
    }
    return <button type={'button'} className={'btn btn-sm btn-primary'} onClick={()=>setActioned()}>
        Mark Actioned
    </button>
}


export default connect(null,mapDispatchToProps)(MarkActionedButton);
