import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField, renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { userListClearFilters, userListSetFilters } from '../../../lib/redux/actions/userList';
import Form from 'react-bootstrap/Form';
import { getFiltersFromSession } from '../../../lib/routing/filters';
//import UserTypeahead from '../../fields/UserTypeahead';

const mapStateToProps = (state, ownProps) => {
  const {location} = ownProps
  let initialValuesFromSession = getFiltersFromSession(location, 'json')
  if (initialValuesFromSession && initialValuesFromSession.roles) {
    const rolesArray = JSON.parse(initialValuesFromSession.roles);
    if (Array.isArray(rolesArray) && rolesArray.length > 0) {
      initialValuesFromSession.roles = rolesArray[0];
    } else {
      initialValuesFromSession.roles = null;
    }
  }
  return {
    initialValues: initialValuesFromSession,
    ...state.consultantList,
  }
};
const mapDispatchToProps = {
  userListSetFilters,
  userListClearFilters,
};

class UserTableFilterForm extends React.Component {
  onSubmit(values) {
    const {location} = this.props;
    if (values.roles && !Array.isArray(values.roles)) {
      values.roles = '["' + values.roles + '"]';
    }
    this.props.userListSetFilters(values, location);
  }

  resetFilters() {
    const { reset, userListClearFilters, location } = this.props;
    userListClearFilters(location);
    reset();
  }

  render() {
    const { handleSubmit, error } = this.props;
    const userStatuses = [
      { value: 1, text: 'Active' },
      { value: 0, text: 'InActive' },
    ];
    const roleOptions = [
      { value: 'ROLE_ADMIN', text: 'Admin' },
      { value: 'ROLE_SENIOR_STAFF', text: 'Senior Staff' },
      { value: 'ROLE_STAFF', text: 'Staff' },
      { value: 'ROLE_OUTSOURCER', text: 'Outsourcer' },
      { value: 'ROLE_CHASER_OUTSOURCER', text: 'Chaser Outsourcer' },
      { value: 'ROLE_PRIMARY_USER', text: 'Consultant' },
      { value: 'ROLE_SUBUSER', text: 'Consultant Sub User' },
    ];

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <Field name="name" label="Name" type="text" component={renderField} />
          <Field name="email" label="Email Address" type="text" component={renderField} />
          <Field name="roles" label="Type" options={roleOptions} component={renderSelect} />
          <Field name="isEnabled" showEmpty={false} label="Status" options={userStatuses} component={renderSelect} />

          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Filter
            </button>
            <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
              Reset
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
      form: 'UserTableFilterForm',
      enableReinitialize: true,
    })(UserTableFilterForm),
);


