import React from 'react';
import Form from 'react-bootstrap/Form';
import phone from 'phone';
import { Field, reduxForm, submit, getFormValues, SubmissionError } from 'redux-form';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { Col, Row } from 'react-bootstrap';
import { ContactRecordTypeCodes } from '../../../apiTypes';

const mapDispatchToProps = {
  addToast,
};
const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined);

const e164 = (value) => (value && value !== '+44' && !/^\+[1-9]\d{10,14}$/i.test(value) ? 'Invalid number format (e.g. +447743603123)' : undefined);

const mapStateToProps = (state, props) => {
  const currentFormValues = getFormValues(props.form)(state);
  //  console.log({ currentFormValues });
  return {
    ...state.contactRecordTypes,
    initialValues: {
      ...props.contact,
      contactRecordType: props.contact?.contactRecordType?.code,
    },
    currentFormValues,
  };
};

function enforcePhoneNumberPattern(string) {
  let validated = phone(string, { country: 'GB' });
  //  console.log({ string, validated });
  if (validated.isValid) {
    return validated.phoneNumber;
  }
  validated = phone(string, { country: null });
  if (validated.isValid) {
    return validated.phoneNumber;
  }
  return string;
}

class ContactRecordForm extends React.Component {
  onSubmit(values) {
    const { contactRecordTypes = [], toggleFormUnderEdit, form } = this.props;
    if (values.mobileNumber && undefined !== e164(values.mobileNumber)) {
      throw new SubmissionError({ mobileNumber: e164(values.mobileNumber) });
    }
    if (!values.mobileNumber || String(values.mobileNumber).length < 1) {
      values.notifyByMobile = false;
    }
    if (!values.email || String(values.email).length < 1) {
      values.notifyByEmail = false;
    }
    const submitVals = {
      ...values,
      contactRecordType: contactRecordTypes.find((o) => o.code === values.contactRecordType),
    };

    if ( !submitVals?.contactRecordType ) {
      throw new SubmissionError({ contactRecordType: "Please set Relationship" });
    }

    const submitReturn = this.props.onSubmit(submitVals);

    toggleFormUnderEdit(form);

    return submitReturn;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { pristine, form, toggleFormUnderEdit } = this.props;
    if (prevProps.pristine !== pristine && !pristine) {
      toggleFormUnderEdit(form);
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      pristine,
      submitting,
      contact,
      showPatientOption = true,
      contactRecordTypes = [],
      reset,
      dispatch,
      toggleFormUnderEdit,
      form,
      //      isNew = false,
      existingInvoice = false,
      currentFormValues,
    } = this.props;
    //    console.log({ initialValues: this.props.initialValues, contact: this.props.contact });
    const contactRecordTypeOptions = contactRecordTypes.map((o) => ({ value: o.code, text: o.name }));
    const visibleOptions = showPatientOption
      ? contactRecordTypeOptions
      : contactRecordTypeOptions.filter((o) => String(o.value) !== String(ContactRecordTypeCodes.Patient));

    return (
      <>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Row>
            <Col sm={12} md={12} lg={10} xl={10}>
              <Row>
                <Form.Group className={'col-xl-4'}>
                  <Field required name="name" label="Name" type="text" component={renderField} />
                  <Field
                    name="contactRecordType"
                    label="Relationship"
                    showEmpty={true}
                    emptyLabel={''}
                    required
                    options={visibleOptions}
                    component={renderSelect}
                    onChange={() => dispatch(submit('remoteSubmit'))}
                  />
                </Form.Group>
                <Form.Group className={'col-xl-4'}>
                  {/*{!!currentFormValues?.mobileNumber && (*/}
                  <Field
                    name="notifyByMobile"
                    id={'notifyByMobile' + contact.id}
                    label="Notifications?"
                    wrapperClassNames={'small float-right mt-1'}
                    component={renderCheckbox}
                  />
                  {/*)}*/}
                  <Field
                    name="mobileNumber"
                    label="Mobile Number"
                    //                    placeholder={'Format must be +447743123321'}
                    type="text"
                    component={renderField}
                    normalize={(value) => {
                      return enforcePhoneNumberPattern(value);
                    }}
                    //                    validate={e164}
                  />
                  <Field name="additionalNumber" label="Additional No." type="text" component={renderField} />
                </Form.Group>
                <Form.Group className={'col-xl-4'}>
                  {/*{!!currentFormValues?.email && (*/}
                  <Field
                    name="notifyByEmail"
                    id={'notifyByEmail' + contact.id}
                    label="Notifications?"
                    component={renderCheckbox}
                    wrapperClassNames={'small float-right mt-1'}
                  />
                  {/*)}*/}
                  <Field validate={email} name="email" label="Email Address" type="email" component={renderField} />
                </Form.Group>
                <div className={'col-xl-3'}></div>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  {!pristine && (
                    <button type="submit" disabled={submitting} className="btn btn-primary btn-sm w-100 mb-1 mt-4">
                      Save
                    </button>
                  )}
                  {!pristine &&
                    existingInvoice &&
                    ((!!currentFormValues?.notifyByMobile && !!currentFormValues?.mobileNumber) ||
                      (!!currentFormValues?.notifyByEmail && !!currentFormValues.email)) && (
                      <button
                        type="button"
                        onClick={handleSubmit((values) =>
                          this.onSubmit({
                            ...values,
                            sendNotification: true,
                          }),
                        )}
                        className="btn btn-primary btn-sm w-100 mb-1">
                        Save and Notify
                      </button>
                    )}

                  {!pristine && (
                    <button
                      type="button"
                      onClick={() => {
                        toggleFormUnderEdit(form);
                        reset();
                      }}
                      disabled={submitting}
                      className="btn btn-danger btn-sm w-100">
                      Discard
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    //      form: 'CreateConsultantTicketForm',
    enableReinitialize: true,
  })(ContactRecordForm),
);
