//const canDeleteNotesRoles = ['ROLE_ADMIN', 'ROLE_SUPERADMIN'];
//
//export const canDeleteNotes = (userData) => {
//  return null !== userData && userData.roles.some(userRoles => canDeleteNotesRoles.includes(userRoles));
//};
//

const hasRole = (role) => {
  if (window.localStorage.getItem('roles') === null) {
    return false;
  }
  return window.localStorage.getItem('roles').includes(role);
};

export const isLoggedInUser = (userId = -1) => {
  if (window.localStorage.getItem('userId') === null) {
    return false;
  }
  return Number(window.localStorage.getItem('userId')) === Number(userId);
};

export const getCurrentUserURI = () => {
  return '/users/' + Number(window.localStorage.getItem('userId'));
};

export const isDevMode = () => {
  return process.env.NODE_ENV === 'development';
};

export const isFTD = () => {
  return hasRole('ROLE_FTD');
};

export const isAdmin = () => {
  return hasRole('ROLE_ADMIN');
};
export const isSuperAdmin = () => {
  return hasRole('ROLE_SUPER_ADMIN');
};

export const isSeniorStaff = (strict = false) => {
  return (!strict && isAdmin()) || hasRole('ROLE_SENIOR_STAFF');
};

export const isStaff = (strict = false) => {
  return (!strict && isAdmin()) || (!strict && isSeniorStaff()) || hasRole('ROLE_STAFF');
};

export const isConsultant = (strict = false) => {
  return (!strict && isAdmin()) || hasRole('ROLE_PRIMARY_USER');
};

export const isSubUser = (strict = false) => {
  return (!strict && isAdmin()) || hasRole('ROLE_SUBUSER');
};

export const isClient = () => {
  return isConsultant(true) || isSubUser(true);
};

export const isOutsourcer = (strict = false) => {
  return (!strict && isAdmin()) || hasRole('ROLE_OUTSOURCER');
};

export const isChaserOutsourcer = (strict = false) => {
  return (!strict && isAdmin()) || hasRole('ROLE_CHASER_OUTSOURCER');
};

export const isUser = () => {
  return hasRole('ROLE_USER');
};

export const canUploadInvoices = () => {
  return hasRole('ROLE_CAN_UPLOAD_INVOICES');
};

export const getSubusersConsultantToManage = () => {
  if (window.localStorage.getItem('consultantToManage') === null) {
    return false;
  }
  return JSON.parse(window.localStorage.getItem('consultantToManage'));
};

const subUserCan = (permission) => {
  const permissions = JSON.parse(window.localStorage.getItem('subUserPermissions'));
  //  console.log('SubUserCan',{permission, permissions});
  return permissions && permissions.length > 0 && permissions.includes(permission);
  //  0: "upload_bills"
  //  1: "receive_invoice_emails"
  //  2: "receive_summary_emails"
  //  3: "view_and_download_invoices"
  //  4: "view_and_download_reports"
};

export const subUserCanUploadBills = () => {
  return subUserCan('upload_bills');
};

export const subUserCanViewAndDownloadInvoices = () => {
  return subUserCan('view_and_download_invoices');
};
export const subUserCanViewAndDownloadReports = () => {
  return subUserCan('view_and_download_reports');
};
