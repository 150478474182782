import {
  INVOICE_NOTIFICATION_EVENT_LIST_REQUEST,
  INVOICE_NOTIFICATION_EVENT_LIST_RECEIVED,
  INVOICE_NOTIFICATION_EVENT_LIST_ERROR,
  INVOICE_NOTIFICATION_EVENT_LIST_UNLOAD, INVOICE_NOTIFICATION_EVENT_SINGLE_UPDATED
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    invoiceNotificationEvents: [],
    pageCount: null,
    currentPage: 1,
    isFetchingInvoiceNotificationEvents: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_NOTIFICATION_EVENT_LIST_REQUEST:
      return {
        ...state,
        isFetchingInvoiceNotificationEvents: true,
      }
    case INVOICE_NOTIFICATION_EVENT_SINGLE_UPDATED:
      let mergedInvoiceNotificationEvents = [...state.invoiceNotificationEvents.filter(a => a.id !== action.data.id), action.data];
      return {
        ...state,
        invoiceNotificationEvents: mergedInvoiceNotificationEvents,
        pageCount: hydraPageCount(action.data),
        isFetchingInvoiceNotificationEvents: false,
      };

    case INVOICE_NOTIFICATION_EVENT_LIST_RECEIVED:
      let invoiceNotificationEvents = action.data['hydra:member'];
      if (action.append) {
        const mergedInvoiceNotificationEvents = [...state.invoiceNotificationEvents, ...action.data['hydra:member']];
        invoiceNotificationEvents = Array.from(new Set(mergedInvoiceNotificationEvents.map((a) => a['@id']))).map((id) => {
          return mergedInvoiceNotificationEvents.find((a) => a['@id'] === id);
        });
      }
      return {
        ...state,
        invoiceNotificationEvents,
        pageCount: hydraPageCount(action.data),
        isFetchingInvoiceNotificationEvents: false,
      };

    case INVOICE_NOTIFICATION_EVENT_LIST_ERROR:
      return {
        ...state,
        isFetchingInvoiceNotificationEvents: false,
      };

    case INVOICE_NOTIFICATION_EVENT_LIST_UNLOAD:
      return {
        invoiceNotificationEvents: [],
        pageCount: null,
        currentPage: 1,
        isFetchingInvoiceNotificationEvents: false,
      };

    default:
      return state;
  }
};

export default reducer;
