import {
  USERS_LIST_REQUEST,
  USERS_LIST_RECEIVED,
  USERS_LIST_ERROR,
  USERS_LIST_SET_PAGE,
  USERS_LIST_CLEAR_FILTERS,
  USERS_LIST_SET_FILTERS,
  USERS_LIST_UNLOAD,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const userListRequest = () => ({
  type: USERS_LIST_REQUEST,
});

export const userListReceived = (data) => ({
  type: USERS_LIST_RECEIVED,
  data,
});

export const userListError = (error) => ({
  type: USERS_LIST_ERROR,
  error,
});

export const userListSetPage = (page) => ({
  type: USERS_LIST_SET_PAGE,
  page,
});

export const userListSetFilters = (filters, routeKey) => ({
  type: USERS_LIST_SET_FILTERS,
  filters,
  routeKey
});

export const userListClearFilters = (routeKey) => ({
  type: USERS_LIST_CLEAR_FILTERS,
  routeKey
});

export const userListUnload = () => ({
  type: USERS_LIST_UNLOAD,
});

export const userListFetch = (page = 1, filters = {}, endPoint = API_ROUTES.USERS) => {
  return (dispatch) => {
    dispatch(userListRequest());
    //console.log('Fetching users ...');
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(endPoint, filters)
      .then((response) => dispatch(userListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(userListError(error));
      });
  };
};

export const relatedToInvoiceBatchUserListFetch = (page = 1, filters = {}, invoiceBatchId) => {
  filters.pagination = false;
  return userListFetch(page, filters, API_ROUTES.USERS_RELATED_TO_INVOICE_BATCH.replace(':id', invoiceBatchId));
};
