import Card from "react-bootstrap/Card";
import { withRouter } from "react-router";
import type { RouteComponentProps } from 'react-router';
import React, {useMemo} from "react";
import {getFiltersFromSession} from "../../lib/routing/filters";
import {filtersToReadableString} from "../../lib/helper/formatting";

interface MatchParams {
    path: string;
}

function AppliedFiltersOutput({match}: RouteComponentProps<MatchParams>) {
    let currentFilters = getFiltersFromSession(match.path, 'json');
    const readableFilters = useMemo(() => {
        if ( currentFilters !== null) {
            return filtersToReadableString(currentFilters)
        }
        return false; // Return false if filters are not available in the session storage.
    }, [currentFilters]);

    if (!readableFilters) return null;
    return (
            <Card.Body className={'bg-info text-white px-2 py-1 small'}>
                <span>Filtering By: {readableFilters}</span>
            </Card.Body>
    );
}

export default withRouter(AppliedFiltersOutput);
