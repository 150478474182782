import { INSURANCE_DETAILS_REQUEST, INSURANCE_DETAILS_UPDATED } from '../constants';

const reducer = (
    state = {
      insuranceDetails: null,
      isFetchingInsuranceDetails: false,
    },
    action,
) => {
  switch (action.type) {
    case INSURANCE_DETAILS_REQUEST:
      return {
        ...state,
      };
    case INSURANCE_DETAILS_UPDATED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
