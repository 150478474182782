import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { invoiceNotificationEventListFetch, invoiceNotificationEventStatusOverride } from '../../lib/redux/actions/invoiceNotificationEvents';
import Alert from 'react-bootstrap/Alert';
import { addToast } from '../../lib/redux/actions/toast';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import { capitalizeWords, niceDateTimeFormatter } from '../../lib/helper/formatting';

const mapStateToProps = (state) => ({
  ...state.invoiceNotificationEventList,
});

const mapDispatchToProps = {
  invoiceNotificationEventListFetch,
  invoiceNotificationEventStatusOverride,
  addToast,
};

class InvoiceNotificationEventListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionedThisSession: [],
    };
  }

  componentDidMount() {
    this.loadNotificationEvents();
  }

  loadNotificationEvents() {
    this.props.invoiceNotificationEventListFetch(1, { 'invoiceNotification.invoice.isPaid': false, 'invoiceNotification.invoice.isVoid': false, 'invoiceNotification.invoice.isWrittenOff': false, status: 'created', pagination: false }).then(() => {
      this.props.invoiceNotificationEventListFetch(1, { 'invoiceNotification.invoice.isPaid': false, 'invoiceNotification.invoice.isVoid': false, 'invoiceNotification.invoice.isWrittenOff': false, status: 'failed', pagination: false }, true).then(() => {
        this.props.invoiceNotificationEventListFetch(1, { 'invoiceNotification.invoice.isPaid': false, 'invoiceNotification.invoice.isVoid': false, 'invoiceNotification.invoice.isWrittenOff': false, status: 'sending', pagination: false }, true).then(() => {
          this.props.invoiceNotificationEventListFetch(1, { 'invoiceNotification.invoice.isPaid': false, 'invoiceNotification.invoice.isVoid': false, 'invoiceNotification.invoice.isWrittenOff': false,  status: 'queued', pagination: false }, true);
        });
      });
    });
  }

  markNotificationAsActionedOrSent(notificationEventId, term) {
    const {
      invoiceNotificationEventStatusOverride,
      addToast,
    } = this.props;
    return invoiceNotificationEventStatusOverride(notificationEventId, term).then(() => {
      this.setState({ actionedThisSession: [...this.state.actionedThisSession, notificationEventId] });
      addToast('Notification ' + notificationEventId + ' set to ' + term, 'success');
    });
  }

  render() {
    const {
      invoiceNotificationEvents,
      isFetchingInvoiceNotificationEvents,
      pageTitle,
    } = this.props;

    return (
        <Card>
          <Card.Header>
            <span className="float-right font-weight-normal small">{invoiceNotificationEvents.length} Notifications</span>
            <div className="d-flex justify-content-between">
              {pageTitle}
            </div>
          </Card.Header>

          {(isFetchingInvoiceNotificationEvents === null || invoiceNotificationEvents.length === 0) && (
              <Card.Body>
                <Alert variant={'info'} className="mb-0">
                  No Notifications found
                </Alert>
              </Card.Body>
          )}

          {!isFetchingInvoiceNotificationEvents && invoiceNotificationEvents.length > 0 && (
              <Card.Body>
                <table className="table">
                  <thead>
                  <tr>
                    <th>Notification</th>
                    <th>Invoice</th>
                    <th>Created At</th>
                    <th>Notification</th>
                    <th>Attempted Action</th>
                    <th>Status</th>
                    <th>Outcome</th>
                    <th>View Detail</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoiceNotificationEvents.sort(
                      (a, b) => a.id - b.id,
                  ).map((notificationEvent) => (
                      <tr key={notificationEvent.id} className={this.state.actionedThisSession.includes(notificationEvent.id) ? 'bg-info' : ''}>
                        <td>{notificationEvent.id}</td>
                        <td>
                          {
                            notificationEvent?.contactRecord?.invoice?.id ?
                                <Link rel="noreferrer" target="_blank" to={ROUTES.INVOICES.SINGLE.replace(':id', notificationEvent.contactRecord.invoice.id)} className="btn btn-sm btn-secondary bg-">
                                  Invoice {notificationEvent?.contactRecord?.invoice.invoiceNo}
                                </Link>
                                : 'Unknown'
                          }
                        </td>
                        <td>{niceDateTimeFormatter(notificationEvent.createdAt)}</td>
                        <td>{capitalizeWords(notificationEvent.invoiceNotification.activityName)}</td>
                        <td>{notificationEvent.type.name} {notificationEvent.type.name === 'Phone Call' ?
                            'made' :
                            `send to ${notificationEvent.sentTo ? notificationEvent.sentTo + ' ' : ''}${notificationEvent.contactRecord?.contactRecordType?.name}`}</td>
                        <td>{capitalizeWords(notificationEvent.status)}</td>
                        <td>{notificationEvent?.sentAt !== null ? 'Sent at ' + niceDateTimeFormatter(notificationEvent.sentAt) : (this.state.actionedThisSession.includes(notificationEvent.id) ? 'Overridden' : 'Unsent')}</td>
                        <td>
                          {notificationEvent.serviceIdentifier &&
                              <a className="btn btn-sm btn-outline-dark mr-2" href={`https://console.twilio.com/us1/monitor/logs/sms?pageSize=50&sid=${notificationEvent.serviceIdentifier}`} rel="noreferrer" target={'_blank'}>View in Twilio</a>}
                        </td>
                        <td>
                          {!this.state.actionedThisSession.includes(notificationEvent.id) && (
                              <>
                                {notificationEvent.serviceIdentifier && <button className="btn btn-sm btn-primary mr-2" onClick={() => this.markNotificationAsActionedOrSent(notificationEvent.id, 'Sent')}>Mark Sent</button>}
                                <button className="btn btn-sm btn-danger" onClick={() => this.markNotificationAsActionedOrSent(notificationEvent.id, 'Actioned')}>Mark Actioned</button>
                              </>
                          )}
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </Card.Body>
          )}
        </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceNotificationEventListContainer);
