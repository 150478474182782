import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { ccsdCodeListSetFilters, ccsdCodeListClearFilters, ccsdCodeListSetPage } from '../../../lib/redux/actions/ccsdCodes';
import Form from 'react-bootstrap/Form';
import { getFiltersFromSession } from '../../../lib/routing/filters';

const mapStateToProps = (state, ownProps) => {
  const {location} = ownProps
  let initialValuesFromSession = getFiltersFromSession(location, 'json')
  return {
    initialValues: initialValuesFromSession,
    ...state.consultantList,
  }
};

const mapDispatchToProps = {
  ccsdCodeListSetPage,
  ccsdCodeListSetFilters,
  ccsdCodeListClearFilters,
};

class CcsdCodeTableFilterForm extends React.Component {
  onSubmit(values) {
    const {location} = this.props;
    this.props.ccsdCodeListSetPage(1);
    this.props.ccsdCodeListSetFilters(values, location);
  }

  resetFilters() {
    const { reset, ccsdCodeListClearFilters, location } = this.props;
    ccsdCodeListClearFilters(location);
    reset();
  }

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <Field name="reference" label="Code" type="text" component={renderField} />
          <Field name="name" label="Description" type="text" component={renderField} />

          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Filter
            </button>
            <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
              Reset
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
      form: 'CcsdCodeTableFilterForm',
      enableReinitialize: true,
    })(CcsdCodeTableFilterForm),
);

