import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  INVOICE_NOTIFICATION_EVENT_LIST_REQUEST,
  INVOICE_NOTIFICATION_EVENT_LIST_RECEIVED,
  INVOICE_NOTIFICATION_EVENT_LIST_ERROR,
  INVOICE_NOTIFICATION_EVENT_LIST_UNLOAD,
  INVOICE_NOTIFICATION_EVENT_LIST_UNLOAD_PERSIST_FILTERS,
    INVOICE_NOTIFICATION_EVENT_STATUS_OVERRIDE_REQUEST,
  INVOICE_NOTIFICATION_EVENT_SINGLE_UPDATED,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceNotificationEventListRequest = () => ({
  type: INVOICE_NOTIFICATION_EVENT_LIST_REQUEST,
});

export const invoiceNotificationEventStatusOverrideRequest = () => ({
  type: INVOICE_NOTIFICATION_EVENT_STATUS_OVERRIDE_REQUEST,
});
export const invoiceNotificationEventSingleUpdated = (data) => ({
  type: INVOICE_NOTIFICATION_EVENT_SINGLE_UPDATED,
  data,
});

export const invoiceNotificationEventListError = (error) => ({
  type: INVOICE_NOTIFICATION_EVENT_LIST_ERROR,
  error,
});

export const invoiceNotificationEventListReceived = (data, append = false) => ({
  type: INVOICE_NOTIFICATION_EVENT_LIST_RECEIVED,
  data,
  append,
});

export const invoiceNotificationEventListUnload = (persistFilters = true, routeKey) => {
  if (persistFilters) {
    return {
      type: INVOICE_NOTIFICATION_EVENT_LIST_UNLOAD_PERSIST_FILTERS,
      routeKey,
    };
  }
  return {
    type: INVOICE_NOTIFICATION_EVENT_LIST_UNLOAD,
    routeKey,
  };
};

export const invoiceNotificationEventListFetch = (page = 1, filters = {}, append = false) => {
  return (dispatch) => {
    dispatch(invoiceNotificationEventListRequest());
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    if (filters.pagination) {
      filters.page = page;
    }
    return requests
        .get(API_ROUTES.INVOICE_NOTIFICATION_EVENTS, filters)
        .then((response) => dispatch(invoiceNotificationEventListReceived(response, append)))
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            return dispatch(userLogout());
          } else if (error.response && error.response.status === 403) {
            return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
          }
          dispatch(invoiceNotificationEventListError(error));
        });
  };
};

export const invoiceNotificationEventStatusOverride = (id, statusOverrideText) => {
  if (!['Sent', 'Actioned'].includes(statusOverrideText)) {
    return new SubmissionError(parseApiErrors({ statusOverrideText: 'Status Override must be one of Sent or Actioned.' }));
  }
  return (dispatch) => {
    dispatch(invoiceNotificationEventStatusOverrideRequest());
    return requests
        .post(API_ROUTES.INVOICE_NOTIFICATION_EVENTS + '/' + id + '/override-status', { statusOverrideText }, true)
        .then((response) => dispatch(invoiceNotificationEventSingleUpdated(response)))
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            return dispatch(userLogout());
          } else if (error.response && error.response.status === 403) {
            return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
          }
          dispatch(invoiceNotificationEventListError(error));
        });
  };
};
